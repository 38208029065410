<template>
  <div>
    <Header :title="'内地/其他地区账户汇款'" :back="true"/>
    <div class="hk-box">
      <div class="theme-color top-tips">从内地/其他地区账户汇款充值需要您先［换汇／购汇］再进行［汇款充值］，通常汇款后1-2个工作日到账。</div>
      <div class="top-box">
        <div class="flex-box">
          <div class="theme-color">币种</div>
          <div>美元</div>
        </div>
        <div class="flex-box">
          <div class="theme-color">限额</div>
          <div>5万美元/笔（取决于银行）</div>
        </div>
        <div class="flex-box">
          <div class="theme-color">手续费</div>
          <div>约20-50美元（由银行收取）</div>
        </div>
        <div class="flex-box">
          <div class="theme-color">到账时间</div>
          <div>预计1-2个工作日</div>
        </div>
        <div class="flex-box">
          <div class="theme-color">特点</div>
          <div>适合大额转账充值</div>
        </div>
      </div>
      <div class="m-tips red">特别提示：</div>
      <div class="m-tips">
        <div>选择从中国内地银行汇款的用户，请通过以下银行进行网上银行汇款，并避免到柜台进行操作。</div>
        <div><span class="red">注：工行</span>建议5000美元以下小额充值</div>
      </div>
      <div class="m-tips">
        <!-- <div>点击图片查看汇款操作指南</div> -->
        <!-- <div>

        </div> -->
      </div>
      <div class="m-tips">为保证客服可以及时确认您的汇款，请您在汇款附言中填写您的姓名拼音和木星国际ID：<span class="theme-color">{{code}}</span></div>
      <div class="title">从内地／其他国家银行账户汇款</div>
      <div class="box-items">
        <div class="m-box">
          <div class="flex-box">
            <div>银行名称</div>
            <div>DBS Bank Ltd,Hong Kong Branch</div>
          </div>
          <div class="flex-box">
            <div>国家/地区</div>
            <div>香港</div>
          </div>
          <div class="flex-box">
            <div>SWIET码</div>
            <div>DBSSHKHH</div>
          </div>
          <div class="flex-box">
            <div>账户名称</div>
            <div>Corres International<i class="theme-color el-icon-warning"></i></div>
          </div>
          <div class="flex-box">
            <div>账户号码</div>
            <div>30013249788</div>
          </div>
          
          <div class="flex-box">
            <div>账户地址</div>
            <div>11/F,The Center,99 Queen's <br/> Road Central,Hong Kong</div>
          </div>
          
          <div class="flex-box">
            <div>附言</div>
            <div>请填写姓名拼音和<br/>木星国际ID：<span class="theme-color">{{code}}</span></div>
          </div>
         
        </div>
      </div>
      <div class="b-tips theme-color"><i class="el-icon-warning"></i> Corres International 和木星国际是什么关系？</div>
      <div class="b-box">
        Corres International是客户在星展银行的资金集合托管账户。打入Corres International的资金为客户资产，与木星国际公司的资金严格隔离。
      </div>
      <div class="b-tips theme-color"><i class="el-icon-warning"></i> 关于转账费用</div>
      <div class="b-box bb-box">
        <div>木星国际不会收取任何转账费用，但银行会收取跨境转账费，一般为20-50美元左右（取决于银行），分为两部分：</div>
        <div>1)境内银行收取$10-30左右的电报＋汇款费；</div>
        <div>2)中转银行收取$10-20左右的费用</div>
      </div>
      <!-- <div class="title">如果你想节省更多的转账费用……？</div>
      <div class="bb-box box-items">
        <div>牛牛建议你办理一个香港账户吧！</div>
        <div>从香港账户转账充值无限制额度，每笔手续费仅约7美元（由银行收取），<span class="theme-color">转账成本立减80%！</span></div>
        牛牛贴心地为你整理好八大银行开设香港账户全攻略，更为你提供木星国际用户开户专享福利，详情参见<a @click="account" class="theme-color underline">《木星国际香港银行账户专享开户》。</a>
      </div> -->
    </div>
    <div class="finacing-footer">
      <div class="add-btn theme-bgcolor" @click="lx">已汇款，通知客服</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
     code:''
    }
  },
  created(){
    let info =  JSON.parse(localStorage.getItem('info'));
    this.code = info.code 
  },
  methods:{
    lx(){
      this.$router.push({path:'banktransfer',query:{type:'mainland'}})
    },
    account(){
      this.$router.push({path:'account'})
    }
  }
}
</script>
<style lang="scss" scoped>
.hk-box{
  background: #fff;
  color: #464646;
  padding: 2.5%;
  .top-tips{
    line-height: 2;
    font-size: .95rem;
    margin: 1% 4%;
  }
  .top-box{
    font-size: .95rem;
    margin: 2% 4% 8%;
    padding-bottom:1rem;
    border-bottom:1px solid #eee;
    .flex-box{
      flex-shrink: 0;
      flex-grow: 0;
      margin:1.8% 0%;
      >:first-child{
        width: 80px;
        text-align: justify;
        text-align-last: justify;
        margin-right:20px
      }
    }
  }
  .m-tips{
    margin: 2% 4% 6%;
    line-height: 1.5;
    font-size: .95rem;
  }
  .m-box{
    font-size: .9rem;
    margin: 2% 4%;
    .flex-box{
      margin:1.8% 0%;
      >:first-child{
        width: 70px;
        text-align: justify;
        text-align-last: justify;
        margin-right:20px
      }
    }
  }
 
  .underline{
    text-decoration: underline;
    font-size: .8rem;
  }
 .b-tips{
  margin: 4% 4% 2%;
 }
 .b-box{
  margin: 4% 4% 2%;
  font-size: .95rem;
  div{
    margin: 5px 0px;
  }
 }
 .bb-box{
  font-size: .95rem;
  margin-bottom:50px;
  div{
    margin: 15px 0px;
  }
 }
}
.finacing-footer{
  background: #fff;
  position: fixed;
  bottom: 0px;
  width: 95%;
  padding: 2.5%;
  .add-btn{
    border-radius:8px;
    width: 100%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
</style>