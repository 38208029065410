var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "内地/其他地区账户汇款", back: true } }),
      _c("div", { staticClass: "hk-box" }, [
        _c("div", { staticClass: "theme-color top-tips" }, [
          _vm._v(
            "从内地/其他地区账户汇款充值需要您先［换汇／购汇］再进行［汇款充值］，通常汇款后1-2个工作日到账。"
          ),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "m-tips red" }, [_vm._v("特别提示：")]),
        _vm._m(1),
        _c("div", { staticClass: "m-tips" }),
        _c("div", { staticClass: "m-tips" }, [
          _vm._v(
            "为保证客服可以及时确认您的汇款，请您在汇款附言中填写您的姓名拼音和木星国际ID："
          ),
          _c("span", { staticClass: "theme-color" }, [
            _vm._v(_vm._s(_vm.code)),
          ]),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v("从内地／其他国家银行账户汇款"),
        ]),
        _c("div", { staticClass: "box-items" }, [
          _c("div", { staticClass: "m-box" }, [
            _vm._m(2),
            _vm._m(3),
            _vm._m(4),
            _vm._m(5),
            _vm._m(6),
            _vm._m(7),
            _c("div", { staticClass: "flex-box" }, [
              _c("div", [_vm._v("附言")]),
              _c("div", [
                _vm._v("请填写姓名拼音和"),
                _c("br"),
                _vm._v("木星国际ID："),
                _c("span", { staticClass: "theme-color" }, [
                  _vm._v(_vm._s(_vm.code)),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._m(8),
        _c("div", { staticClass: "b-box" }, [
          _vm._v(
            " Corres International是客户在星展银行的资金集合托管账户。打入Corres International的资金为客户资产，与木星国际公司的资金严格隔离。 "
          ),
        ]),
        _vm._m(9),
        _vm._m(10),
      ]),
      _c("div", { staticClass: "finacing-footer" }, [
        _c(
          "div",
          { staticClass: "add-btn theme-bgcolor", on: { click: _vm.lx } },
          [_vm._v("已汇款，通知客服")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-box" }, [
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("币种")]),
        _c("div", [_vm._v("美元")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("限额")]),
        _c("div", [_vm._v("5万美元/笔（取决于银行）")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("手续费")]),
        _c("div", [_vm._v("约20-50美元（由银行收取）")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("到账时间")]),
        _c("div", [_vm._v("预计1-2个工作日")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-color" }, [_vm._v("特点")]),
        _c("div", [_vm._v("适合大额转账充值")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "m-tips" }, [
      _c("div", [
        _vm._v(
          "选择从中国内地银行汇款的用户，请通过以下银行进行网上银行汇款，并避免到柜台进行操作。"
        ),
      ]),
      _c("div", [
        _c("span", { staticClass: "red" }, [_vm._v("注：工行")]),
        _vm._v("建议5000美元以下小额充值"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("银行名称")]),
      _c("div", [_vm._v("DBS Bank Ltd,Hong Kong Branch")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("国家/地区")]),
      _c("div", [_vm._v("香港")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("SWIET码")]),
      _c("div", [_vm._v("DBSSHKHH")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("账户名称")]),
      _c("div", [
        _vm._v("Corres International"),
        _c("i", { staticClass: "theme-color el-icon-warning" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("账户号码")]),
      _c("div", [_vm._v("30013249788")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("账户地址")]),
      _c("div", [
        _vm._v("11/F,The Center,99 Queen's "),
        _c("br"),
        _vm._v(" Road Central,Hong Kong"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "b-tips theme-color" }, [
      _c("i", { staticClass: "el-icon-warning" }),
      _vm._v(" Corres International 和木星国际是什么关系？"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "b-tips theme-color" }, [
      _c("i", { staticClass: "el-icon-warning" }),
      _vm._v(" 关于转账费用"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "b-box bb-box" }, [
      _c("div", [
        _vm._v(
          "木星国际不会收取任何转账费用，但银行会收取跨境转账费，一般为20-50美元左右（取决于银行），分为两部分："
        ),
      ]),
      _c("div", [_vm._v("1)境内银行收取$10-30左右的电报＋汇款费；")]),
      _c("div", [_vm._v("2)中转银行收取$10-20左右的费用")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }